<template></template>

<script>
import store from '@/store';
import routes from '@/store/modules/routes';

export default {
  name: 'Logout',
  mounted() {
    store.dispatch('logout');
    store.dispatch('resetSelectedCompany');
    this.$router.replace({
      name: routes.state.appRoutes['LOGIN']
    });
  }
};
</script>
